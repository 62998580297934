<template>
    <div class="editor">
        <editor-menu-bar v-bind:editor="editor"
                         v-slot="{ commands, isActive }">
            <div class="menubar is-flex">
                <div class="field has-addons mr-2">
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.bold() }"
                                v-on:click="commands.bold">
                            <font-awesome-icon icon="bold"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.italic() }"
                                v-on:click="commands.italic">
                            <font-awesome-icon icon="italic"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.strike() }"
                                v-on:click="commands.strike">
                            <font-awesome-icon icon="strikethrough"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.underline() }"
                                v-on:click="commands.underline">
                            <font-awesome-icon icon="underline"></font-awesome-icon>
                        </button>
                    </p>
                </div>

                <div class="field has-addons mr-2">
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.paragraph() }"
                                v-on:click="commands.paragraph">
                            <font-awesome-icon icon="paragraph"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.heading({ level: 1 }) }"
                                v-on:click="commands.heading({ level: 1 })">
                            <font-awesome-icon icon="h1"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.heading({ level: 2 }) }"
                                v-on:click="commands.heading({ level: 2 })">
                            <font-awesome-icon icon="h2"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.heading({ level: 3 }) }"
                                v-on:click="commands.heading({ level: 3 })">
                            <font-awesome-icon icon="h3"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.heading({ level: 4 }) }"
                                v-on:click="commands.heading({ level: 4 })">
                            <font-awesome-icon icon="h4"></font-awesome-icon>
                        </button>
                    </p>
                </div>

                <div class="field has-addons mr-2">
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.bullet_list() }"
                                v-on:click="commands.bullet_list">
                            <font-awesome-icon icon="list-ul"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.ordered_list() }"
                                v-on:click="commands.ordered_list">
                            <font-awesome-icon icon="list-ol"></font-awesome-icon>
                        </button>
                    </p>
                </div>

                <div class="field has-addons mr-2">
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.code() }"
                                v-on:click="commands.code">
                            <font-awesome-icon icon="code"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.code() }"
                                v-on:click="commands.code">
                            <font-awesome-icon icon="code"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.blockquote() }"
                                v-on:click="commands.blockquote">
                            <font-awesome-icon icon="quote-left"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-bind:class="{ 'is-active': isActive.code_block() }"
                                v-on:click="commands.code_block">
                            <font-awesome-icon icon="brackets-curly"></font-awesome-icon>
                        </button>

                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-on:click="commands.horizontal_rule">
                            <font-awesome-icon icon="horizontal-rule"></font-awesome-icon>
                        </button>
                    </p>
                </div>
                <div class="field has-addons mr-2">
                    <p class="control">
                        <button class="button is-small"
                                v-on:click="showImagePrompt(commands.image)">
                            <font-awesome-icon icon="image"></font-awesome-icon>
                        </button>
                    </p>
                </div>

                <div class="field has-addons">
                    <p class="control">
                        <button class="button is-small"
                                v-on:click="commands.undo">
                            <font-awesome-icon icon="undo"></font-awesome-icon>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button is-small"
                                v-on:click="commands.redo">
                            <font-awesome-icon icon="redo"></font-awesome-icon>
                        </button>
                    </p>
                </div>
            </div>
        </editor-menu-bar>

        <div class="content-wrapper">
            <editor-content class="editor__content"
                            v-bind:editor="editor">
            </editor-content>
        </div>
    </div>
</template>

<script>
import {
    Editor,
    EditorContent,
    EditorMenuBar,
} from "tiptap";

import {
    Blockquote,
    BulletList,
    CodeBlock,
    HardBreak,
    Heading,
    ListItem,
    OrderedList,
    TodoItem,
    TodoList,
    Bold,
    Code,
    Italic,
    Link,
    Strike,
    Underline,
    History,
    HorizontalRule,
    Image,
    Focus,
} from "tiptap-extensions";

export default {
    name: "Editor",
    components: {
        EditorContent,
        EditorMenuBar,
    },
    props: {
        "value": {
            type: String,
        },
    },
    data() {
        return {
            "editor": new Editor(
                {
                    "extensions": [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new HardBreak(),
                        new Heading({levels: [1, 2, 3, 4, 5]}),
                        new HorizontalRule(),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Link(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Strike(),
                        new Underline(),
                        new History(),
                        new Image(),
                        new Focus(
                            {
                                className: "has-focus",
                                nested: true,
                            }
                        ),
                    ],
                    "autoFocus": true,
                    "content": "",
                    "onUpdate": ({ getHTML }) => {
                        this.html = getHTML();
                    },
                }
            ),
            html: "",
        };
    },
    computed: {

    },
    methods: {
        showImagePrompt (command) {
            const src = prompt("Enter the url of your image here");
            if (src !== null) {
                command({ src })
            }
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    mounted () {
        if (this.value) {
            this.editor.setContent(this.value);
        }
    },
    watch: {
        "value" (newVal) {
            if (newVal !== this.editor.getHTML()) {
                this.editor.setContent(newVal);
                if (newVal === "") {
                    this.html = "";
                }
            }
        },

        "html" (newVal) {
            this.$emit("input", newVal);
        },
    },
}
</script>

<style lang="scss" scoped>
.content-wrapper {
    border: solid 1px #dbdbdb;
    border-radius: 2px;
    padding: 0.5rem;

    .editor__content {
        min-height: 150px;
    }
}

.has-focus {
    border-radius: 3px;
    box-shadow: 0 0 0 3px #3ea4ff;
}
</style>


<style lang="scss">
.ProseMirror {
    &:focus {
        outline: none;
    }
}
</style>

